import { ConfigProvider } from 'antd'
import 'antd/dist/reset.css'
import locale from 'antd/locale/ko_KR'
import AOS from 'aos'
import "aos/dist/aos.css"
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Home } from './pages/home/Home'
import "./styles/font.scss"
import "./styles/index.scss"
import { ServiceTerm } from './pages/term/ServiceTerm'
import { PrivacyTerm } from './pages/term/PrivacyTerm'

AOS.init({
    throttleDelay: 399,
    offset: 320,
})

const App = () => {
    return (
        <ConfigProvider
            locale={locale}
            theme={{
                token: {
                    colorPrimary: '#F8B31A',
                    borderRadius: 4,

                    colorBgContainer: '#fdfeff',
                },
                components: {
                    Menu: {
                        horizontalLineHeight: '62px',
                        itemHoverBg: '#fff3d9',
                    }
                }
            }}
        >
            <BrowserRouter>
                <Routes>
                    <Route path="/privacy-terms" element={<PrivacyTerm />} />
                    <Route path="/service-terms" element={<ServiceTerm />} />

                    <Route path="*" element={<Home />} />
                </Routes>
            </BrowserRouter>
        </ConfigProvider>
    )
}

export default App