import { Col, Divider, Layout, Row, Space, Typography } from "antd"
const { Text, Paragraph, Title, } = Typography

export const ServiceTerm = () => {
    return (
        <Layout.Content
            style={{
                maxWidth: 1100,
                margin: '0 auto'
            }}
        >
            <Row
                justify={'center'}
                style={{ padding: 16 }}
            >
                <Col xs={{ span: 24 }} style={{ backgroundColor: '#fff', marginBottom: 30, }}>
                    <Layout.Content style={{ backgroundColor: '#fff', textAlign: 'center', marginTop: 12, }}>
                        <Title level={3}>서비스 이용약관</Title>
                        <Divider />
                        {/* <Title level={5} style={{ color: '#999' }}>본 약관은 2023-03-21부터 적용됩니다.</Title> */}
                    </Layout.Content>
                    <Space direction="vertical" size="large">
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Title level={5}>제1장 총칙</Title>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제1조 (목적)
                                        </Text>
                                        <Text>
                                            이 약관은 맘마플래너(이하 “회사”라 합니다)가 제공하는 모바일앱 서비스 및 이에 부수하는 기타 서비스(이하 “서비스”라 합니다)의 이용에 대한 회사와 회원의 권리ㆍ의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
                                        </Text>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제2조 (용어의 정의)
                                        </Text>
                                        <Text>
                                            이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                                        </Text>
                                    </Space>
                                </Paragraph>
                                <Paragraph style={{ paddingLeft: 16, }}>
                                    <Space direction="vertical">
                                        <Text>① “회원”이란 “회사가 정한 회원 가입 절차를 거쳐 약관에 따라 이용계약을 체결하고, 회사가 제공하는 서비스를 이용하는 자를 의미합니다.</Text>
                                        <Text>② “아이디(ID)”란 “회원”의 식별과 “서비스” 이용을 위하여 “회원이”정하고 “회사”가 승인하는 이메일 주소 형식의 문자 또는 숫자, 기호의 조합을 말합니다.</Text>
                                        <Text>③ “비밀번호”란 “회원”이 부여 받은 아이디와 일치되는 “회원”임을 확인하고 비밀보호를 위해 “회원” 자신이 정한 문자 또는 숫자, 기호의 조합을 말합니다.</Text>
                                        <Text>④ “게시물”이란 “회원”이 “서비스”를 이용함에 있어 “서비스상”에 게시한 부호‧문자‧음성‧음향‧화상‧동영상 등 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.</Text>
                                    </Space>
                                </Paragraph>
                                <Paragraph>
                                    <Text type="secondary">
                                        이 약관에서 사용하는 용어의 정의는 본 조 제1항 각 호에서 정하는 것을 제외하고는 관계 법령 및 개인정보처리방침, 서비스별 정책에서 정하는 바에 의하며, 회사가 별도로 정한 지침 기타 일반적인 상관례에 의합니다.
                                    </Text>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제3조 (약관의 명시 및 개정)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical">
                                                <Text>① 회사는 약관의 내용을 회원이 쉽게 알 수 있도록 앱 서비스 내 페이지 화면에 게시합니다.</Text>
                                                <Text>② 회사는「약관의 규제에 관한 법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」등 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</Text>
                                                <Text>③ 회사는 약관을 개정하는 경우 적용일자 및 개정사유를 개정 전 약관과 함께 적용일자 7일 전(회원에게 불리하거나 중대한 사항의 변경은 30일 이전)부터 서비스 공지사항에 게시하는 방법으로 회원에게 통지합니다. 다만, 회원에게 불리한 내용의 약관 개정은 서비스 공지 외에 이메일 전송 등의 방법으로 회원에게 추가 통지합니다.</Text>
                                                <Text>④ 회사가 전항에 따라 개정 약관을 공지 또는 통지하면서 개정 약관의 시행일까지 거부의 의사표시를 하지 않으면 동의한 것으로 볼 수 있다는 내용도 함께 공지 또는 통지를 하였음에도 회원이 거부의 의사표시를 하지 않는다면 개정약관에 동의한 것으로 봅니다.</Text>
                                                <Text>⑤ 회사는 개정 약관의 적용에 동의하지 않는 회원에 대하여 개정 약관의 내용을 적용할 수 없습니다. 단, 이 경우 회사 및 회원은 이용계약을 해지할 수 있습니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제4조 (약관의 해석)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical">
                                                <Text>① 회사는 필요한 경우, 개별 서비스에 대해서는 별도의 이용약관 및 정책을 둘 수 있으며해당 내용이 이 약관과 상충할 경우에는 “개별 서비스 약관”이 우선하여 적용됩니다.</Text>
                                                <Text>② 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 개별 서비스 약관 및 개인정보처리방침, 관련 법령 또는 상관례에 따릅니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제5조 (정보의 통지)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical">
                                                <Text>① 회사는 회원이 제공한 이메일 주소(회원이 제공한 SNS 계정을 통하여 제공받는 경우를 포함합니다)로 이메일을 전송하는 방법을 통해 회원에게 정보를 통지할 수 있습니다.</Text>
                                                <Text>② 회사는 불특정 다수 회원에 대한 통지의 경우, 서비스 공지사항 또는 게시판에 7일 이상의 기간 동안 그 통지를 게시하는 방법으로 제1항의 통지를 갈음할 수 있습니다. 다만, 특정 회원의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 제1항에 따라 개별적으로 통지를 하여야 합니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                        </Layout.Content>
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Title level={5}>제2장 서비스 이용</Title>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제6조 (이용계약의 체결)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical" wrap>
                                                <Text>① 이용계약은 약관의 내용에 동의하여 회원이 되고자 하는 자(이하 “가입신청자”라 합니다)가 회사에 가입신청을 하고, 회사가 이러한 신청에 대하여 가입을 승낙함으로써 체결됩니다.</Text>
                                                <Text>
                                                    ② 회사는 전항의 가입 신청에 대하여 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호의 경우 가입신청의 승낙을 거절할 수 있으며, 사후적으로 회원에게 다음 각 호의 사유가 존재함을 안 경우 그 회원과의 이용계약을 해지할 수 있습니다.
                                                    <Paragraph style={{ paddingLeft: 16, }}>
                                                        <Space direction="vertical" wrap size={2}>
                                                            <Text>1. 가입신청자가 타인의 명의를 도용하여 가입신청을 한 경우</Text>
                                                            <Text>2. 가입신청과 관련하여 허위의 또는 부정확한 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</Text>
                                                            <Text>3. 가입신청자가 이전에 본 항 각 호의 사유로 인하여 회사에 의해 이용계약이 해지된 적이 있는 경우. 단 회사로부터 회원 재가입에 대한 승낙을 얻은 경우는 예외로 함</Text>
                                                            <Text>4. 14세 미만 아동이 법정 대리인의 동의를 얻지 아니한 경우</Text>
                                                            <Text>5. 기타 제반 규정 위반 등 가입신청자의 귀책사유로 인하여 회사가 가입신청의 승낙을 거절할 만한 객관적인 사유가 존재하는 경우</Text>
                                                        </Space>
                                                    </Paragraph>
                                                </Text>
                                                <Text>
                                                    ③ 회사는 다음 각 호의 어느 하나에 해당하는 경우 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
                                                    <Paragraph style={{ paddingLeft: 16, }}>
                                                        <Space direction="vertical" wrap size={2}>
                                                            <Text>1. 회사의 설비에 여유가 없거나, 특정 모바일 기기의 지원이 어렵거나, 기술적 장애 또는 업무상 문제가 있는 경우</Text>
                                                            <Text>2. 서비스 상의 장애 또는 서비스 이용요금, 결제수단의 장애가 발생한 경우</Text>
                                                            <Text>3. 그 밖의 각 호에 준하는 사유로서 이용신청의 승낙이 어렵다고 판단되는 경우</Text>
                                                        </Space>
                                                    </Paragraph>
                                                </Text>
                                                <Text>④ 본 조에 따라서 회원가입신청의 승낙을 거절 또는 유보하는 경우, 회사는 해당 사실을 가입신청자에게 통지하여야 합니다.</Text>
                                                <Text>⑤ 회사가 서비스 가입신청 절차상에서 가입신청자에게 가입완료 사실을 통지함으로써 이용계약이 성립하며, 그에 따라 해당 가입신청자는 회원의 지위에서 회사가 제공하는 서비스를 이용할 수 있습니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제7조 (회원정보 변경)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical">
                                                <Text>① 회원은 언제든지 본인 및 본인이 관리하는 객체의 개인정보를 열람하고 수정할 수 있습니다. 다만 서비스 관리를 위해 필요한 회원 ID는 수정이 불가합니다.</Text>
                                                <Text>② 전항의 변경 사항에 대해 정보 수정을 진행하지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제8조 (서비스 이용요금)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical">
                                                <Text>① 서비스 이용요금(이하 “이용요금”이라 합니다)은 원칙적으로 무료입니다. 그러나 일부 서비스(기능) 또는 콘텐츠(이하 “유료 서비스 등”이라 합니다)의 경우 유료로 제공될 수 있습니다.</Text>
                                                <Text>② 회사는 유료 서비스 등의 가격 및 지급방법, 지급시기 등 구체적인 사항을 정하여 공지사항 등 앱 서비스 페이지 내 게시합니다.</Text>
                                                <Text>③ 이용요금에 관한 사항(기존에 무료로 제공되던 서비스의 유료화 등을 포함합니다)은 회사의 정책에 따라 변경될 수 있습니다.</Text>
                                                <Text>④ 회원은 회사와의 별도 합의를 통하여 본 조와 다른 내용으로 이용요금에 관한 사항을 정할 수 있습니다. 회사와 회원 사이에 별도 체결된 계약서의 내용은 서비스 공지사항 또는 게시판의 게시된 내용에 대하여 효력상 우선합니다.</Text>
                                                <Text>⑤ 회원이 서비스를 이용하는 과정에서 통신사업자 등과의 거래관계에서 발생하는 데이터 통신 요금은 제1항의 이용요금과 별개의 것이며, 회사는 위 데이터 통신 요금과 관련하여 어떠한 의무 또는 책임도 부담하지 않음을 분명히 합니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제9조 (서비스의 제공)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical" wrap>
                                                <Text>
                                                    ① 회사는 관련 법령상 허용되는 그리고 기술적으로 가능한 범위 내에서 회원에게 아래와 같은 서비스를 제공합니다.
                                                    <Paragraph style={{ paddingLeft: 16, }}>
                                                        <Space direction="vertical" wrap size={2}>
                                                            <Text>1. 이유식 기록 서비스</Text>
                                                            <Text>2. 이유식 큐브 재고 관리 서비스</Text>
                                                            <Text>3. 이유식 재료간 음식궁합 서비스</Text>
                                                            <Text>4. 통계 및 분석 서비스</Text>
                                                            <Text>5. 커뮤니티 서비스</Text>
                                                            <Text>6. 기타 이유식 관련 정보 제공 서비스 등</Text>
                                                        </Space>
                                                    </Paragraph>
                                                </Text>
                                                <Text>② 회원이 서비스를 이용하는 국가 및 회원의 단말기 모델에 따라 회사가 제공하는 서비스의 내용 및/또는 범위가 달라질 수 있습니다.</Text>
                                                <Text>③ 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능한 지역 및 시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.</Text>
                                                <Text>
                                                    ④ 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 일시적으로 변경하거나 중단할 수 있으며 관련 법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.
                                                    <Paragraph style={{ paddingLeft: 16, }}>
                                                        <Space direction="vertical" wrap size={2}>
                                                            <Text>1. 설비의 보수점검, 교체 등 공사로 인하여 부득이한 경우</Text>
                                                            <Text>2. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우</Text>
                                                            <Text>3. 국가기관 또는 정부조직(방송통신위원회, 한국정보보호진흥원 등), 수사기관, 법원 등의 행정 또는 사법적 처분 등에 따라 법상 서비스의 제공을 제한 또는 중단하여야 하는 경우</Text>
                                                            <Text>4. 기타 회사의 운영상, 기술상 필요 등 상당한 이유가 있는 경우</Text>
                                                        </Space>
                                                    </Paragraph>
                                                </Text>
                                                <Text>⑤ 전항에 따라 서비스가 변경 또는 중단되는 경우에는 그 변경 또는 중단 사실을 서비스 페이지 내에 적용일 7일전부터 게시하여 공지합니다. 다만, 버그 오류 등의 수정이나 긴급 업데이트 등 부득이하게 변경할 필요가 있는 경우 또는 중대한 변경에 해당하지 않는 경우에는 사후에 공지할 수 있습니다.</Text>
                                                <Text>⑥ 서비스는 연중무휴, 1일 24시간 제공을 원칙으로 합니다.</Text>
                                                <Text>⑦ 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제10조 (광고 기타 정보의 제공)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical">
                                                <Text>① 회사는 서비스 이용과 관련한 정보를 제7조에 정한 방법으로 회원에게 제공할 수 있습니다. 회원은 관련 법령에 따른 거래관련 정보 및 회원 문의 등에 대한 답변 등을 제외하고는 언제든지 회사가 제공하는 정보가 포함된 이메일의 수신을 거절할 수 있습니다.</Text>
                                                <Text>② 회사는 서비스 화면, 앱, 이메일 등을 통하여 회원에게 광고 등 정보를 제공할 수 있습니다. 회원은 회사에 대한 의사표시로서 광고가 포함된 이메일에 대한 수신을 거절할 수 있습니다.</Text>
                                                <Text>③ 회원은 회사가 제공하는 정보 및 광고가 포함된 이메일에 대한 수신을 동의한 상태에서 이용계약 해지(탈퇴)를 하지 않고 앱을 삭제 혹은 단말기의 공장초기화를 진행한 경우, 앱 삭제 혹은 공장초기화 이전의 수신동의는 계속 유효합니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                        </Layout.Content>
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Title level={5}>제3장 계약 당사자의 권리 의무</Title>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제11조 (회사의 의무)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical" wrap>
                                                <Text>① 회사는 관련 법령, 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 “서비스”를 제공하기 위하여 최선을 다하여 노력합니다.</Text>
                                                <Text>② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)를 보호하기 위하여 최선을 다하며, 개인정보처리방침을 공시하고 준수합니다.</Text>
                                                <Text>③ 회사는 서비스 이용과 관련한 회원의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구축 및 유지할 수 있도록 최선을 다합니다.</Text>
                                                <Text>④ 회사는 서비스 이용과 관련하여 회원이 정당한 의견 또는 불만을 제기하는 경우, 이를 처리하고 이메일 등을 통하여 회원에게 그 처리과정 및 결과를 신속하게 전달합니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제12조 (회원의 의무)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical">
                                                <Text>
                                                    ① 회원은 서비스의 이용과 관련하여 다음 각 호에 해당하는 행위를 해서는 안 됩니다.
                                                    <Paragraph style={{ paddingLeft: 16, }}>
                                                        <Space direction="vertical" wrap size={2}>
                                                            <Text>1. 가입신청 또는 회원 정보 변경 시 허위사실을 기재하는 행위</Text>
                                                            <Text>2. 회사의 사전 승낙 없이 회사가 게시한 정보의 변경 또는 삭제</Text>
                                                            <Text>3. 회사의 직원이나 운영자를 가장하거나 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위</Text>
                                                            <Text>4. 타인의 금융 정보를 도용하여 유료 콘텐츠를 구매하거나 다른 회원의 ID 및 비밀번호를 부정 사용하는 행위</Text>
                                                            <Text>5. 회사나 타인의 지적재산권 또는 초상권을 침해하는 행위, 타인의 명예를 훼손하거나 업무를 방해하는 행위</Text>
                                                            <Text>6. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위</Text>
                                                            <Text>7. 회사의 서비스를 이용하여 얻은 정보를 무단으로 복제, 유통, 조장하거나 상업적으로 이용하는 행위</Text>
                                                            <Text>8. 그 밖에 관련 법령에 위반되거나 선량한 풍속 기타 사회통념에 반하는 행위</Text>
                                                        </Space>
                                                    </Paragraph>
                                                </Text>
                                                <Text>② 회원은 회사의 사전 승낙 없이는 회사가 제공하는 서비스를 이용하여 회사의 영업과 동종의 또는 유사한 영업을 영위할 수 없습니다.</Text>
                                                <Text>③ 회사는 회사의 사전 승낙 없이 회원이 행한 제3항의 동종·유사 영업에 대하여 어떠한 책임도 부담하지 않으며, 회원의 위 영업으로 인하여 회사에게 손해가 발생한 경우 해당 회원은 회사가 입은 손해를 전액 배상하여야 합니다.</Text>
                                                <Text>④ 회원이 본 조의 의무를 위반하는 경우, 회사는 관계법령상 허용되는 범위 내에서 그에 대한 관련 콘텐츠의 삭제, 서비스 이용제한 등 기타 필요한 조치를 취할 수 있습니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제13조 (회원의 계정 정보 관리에 대한 의무 및 책임)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical">
                                                <Text>① 회원 계정 정보(이메일 주소, 비밀번호, 유저네임 등을 포함합니다)에 관한 관리책임은 회원에게 있으며, 회원은 자신의 계정 정보를 제3자가 이용하도록 하여서는 안됩니다.</Text>
                                                <Text>② 회원은 제3자가 자신의 계정 정보 등 개인정보를 이용하고 있음을 인지한 경우 즉시 비밀번호 등을 변경하고, 회사에 이러한 사실을 통지하고 이후 회사의 안내에 따라야 합니다.</Text>
                                                <Text>③ 제2항의 경우에 해당 회원이 회사에게 제3자의 개인정보 이용 사실 등을 통지하지 않거나, 회사의 안내에 따르지 않음에 따라 발생한 불이익에 대하여, 회사는 어떠한 책임도 부담하지 않습니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제14조 (권리의 귀속)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical" wrap>
                                                <Text>① 회사가 서비스와 관련하여 회원에게 제공하는 서비스, 앱 등에 관한 일체의 정보 및 자료에 대한 소유권 및 특허권, 저작권 기타 일체의 지식재산권은 회사에 귀속합니다. 단, 회원의 게시물 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.</Text>
                                                <Text>② 회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정, 아이디, 콘텐츠 등을 이용할 수 있는 이용권만을 부여하며, 회원은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.</Text>
                                                <Text>③ 회사는 비상업적 용도에 이용결과물을 자유롭게 이용할 수 있으며, 회원으로부터 별도 동의를 얻어 이용결과물을 상업적 용도에 이용할 수 있습니다.</Text>
                                                <Text>④ 회원은 서비스를 이용함으로써 얻은 정보 중 회사에 지적재산권이 귀속된 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 및 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 해서는 안됩니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제15조 (“게시물”의 저작권)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical">
                                                <Text>① 회원이 서비스 내 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀속됩니다.</Text>
                                                <Text>② 회원이 서비스 내에 게시하는 게시물은 검색결과 내지 서비스 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우, 회사는 저작권법 규정을 준수하며, 회원은 언제든지 고객센터 또는 서비스 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색 결과 제외, 비공개 등의 조치를 취할 수 있습니다.</Text>
                                                <Text>③ 회사는 제2항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우에는 이메일 등을 통해 사전에 회원의 동의를 얻어야 합니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제16조 (“게시물”의 관리)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical">
                                                <Text>① 회원의 게시물이 “정보통신망법” 및 “저작권법” 등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련법에 따라 조치를 취하여야 합니다.</Text>
                                                <Text>② 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                        </Layout.Content>
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Title level={5}>제4장 계약 해지</Title>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제17조 (계약해지 등)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical" wrap>
                                                <Text>① 회원은 언제든지 회사와의 이용계약을 해지할 수 있으며, 회사는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.</Text>
                                                <Text>② 회원이 계약을 해지할 경우, 관련법 및 개인정보처리방침에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 데이터는 소멸됩니다. 단, 비식별화된 데이터는 해지 이후에도 통계의 목적으로 이용할 수 있습니다.</Text>
                                                <Text>
                                                    ③ 회사는 회원에게 다음 각 호의 사유가 존재하는 경우 이용계약을 해지할 수 있습니다.
                                                    <Paragraph style={{ paddingLeft: 16, }}>
                                                        <Space direction="vertical" wrap size={2}>
                                                            <Text>1. 제6조 제2항의 승낙거절사유가 존재하는 경우</Text>
                                                            <Text>2. 제12조에 규정된 회원의 의무를 위반하는 경우</Text>
                                                            <Text>3.“저작권법” 및 “컴퓨터 프로그램 보호법”을 위반한 불법프로그램의 제공 및 운영방해, “정보통신망법”을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등 관련법을 위반한 경우</Text>
                                                            <Text>4. 기타 회사가 해당 회원에 대한 서비스의 제공을 거부할 만한 합리적 사유가 존재하는 경우</Text>
                                                        </Space>
                                                    </Paragraph>
                                                </Text>
                                                <Text>④ 회사는 해지 사유가 존재하는 경우 제5조에 따라 통지 후 서비스 이용계약을 해지할 수 있습니다. 단, 회원이 현행법 위반 및 고의 또는 중대한 과실로 회사에 손해를 입힌 경우, 회사는 사전 통지 없이 해당 회원과의 이용계약을 해지할 수 있습니다.                                                </Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                        </Layout.Content>
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Title level={5}>제5장 기타</Title>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제18조 (손해배상)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical" wrap>
                                                <Text>① 회사와 회원은 서비스 이용과 관련하여 고의 또는 과실로 상대방에게 손해를 끼친 경우에는 이를 배상하여야 합니다.</Text>
                                                <Text>② 단, 회사는 무료로 제공하는 서비스의 이용과 관련하여 개인정보처리방침에서 정하는 내용이나 기타 관련 법령에 위반하지 않는 한 손해배상 책임을 지지 않습니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제19조 (회사의 면책)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical" wrap>
                                                <Text>① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</Text>
                                                <Text>② 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.</Text>
                                                <Text>③ 회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.</Text>
                                                <Text>④ 회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 한 거래, 이용결과물의 지식재산권 침해 관련 분쟁 등에 대하여 어떠한 책임도 부담하지 않습니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제20조 (개인정보 등의 보호)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical" wrap>
                                                <Text>① 회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의 개인정보처리방침에 따릅니다. 다만, 회사가 제공하는 서비스 이외의 링크된 서비스에서는 회사의 개인정보처리방침이 적용되지 않습니다.</Text>
                                                <Text>② 회사는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 모든 정보에 대해서 일체의 책임을 지지 않습니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제21조 (통계정보의 가공)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical" wrap>
                                                <Text>① 회사는 회원 및 이용계약이 해지된 회원으로부터 수집한 개인 정보를 파기한 후 서비스 이용기록의 통계정보 가공을 위해 별도의 백업 DB에 해당 정보를 가명정보(이하 “통계자료”라고 합니다)로 분리 보관합니다.</Text>
                                                <Text>② 회사는 동조 1항의 백업 DB에 보관된 서비스 이용기록 등을 통계학적 방법으로 정리, 분석하여 통계자료로만 가공하며, 가공한 통계 자료의 소유권, 저작권 등의 지적재산권 및 기타 모든 법적 권리는 회사가 소유합니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제22조 (통계자료의 제공)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical" wrap>
                                                <Text>
                                                    ① 제 21조의 통계자료는 회원 또는 다음 각 호에 해당하는 자에게 제공되어 활용될 수 있습니다.
                                                    <Paragraph style={{ paddingLeft: 16, }}>
                                                        <Space direction="vertical" wrap size={2}>
                                                            <Text>1. 정부</Text>
                                                            <Text>2. 공공기관</Text>
                                                            <Text>3. 식음료업체</Text>
                                                            <Text>4. 기타 회사가 제공할 필요가 있다고 인정하는 개인 또는 단체 등</Text>
                                                        </Space>
                                                    </Paragraph>
                                                </Text>
                                                <Text>② 회사는 가공된 통계자료에 대한 접근권한을 최소한으로 관리하여야 합니다.</Text>
                                                <Text>③ 회사는 통계자료를 상업적 목적으로 제 3자에게 제공할 수 있습니다. 단, 기술적 방법을 통한 가공 이전의 정보는 회원들의 동의 없이 제 3자에게 제공하지 아니합니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제23조 (준거법 및 재판관할)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical" wrap>
                                                <Text>① 이 약관 및 이용계약에 관한 분쟁은 대한민국 법을 준거법으로 합니다.</Text>
                                                <Text>② 약관 및 이용계약에 관하여 회사와 회원 사이에 발생한 분쟁에 관한 소송은 서울중앙지방법원을 제1심 관할법원으로 하여 해결합니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                        </Layout.Content>
                    </Space>
                </Col>
            </Row>
        </Layout.Content>
    )
}